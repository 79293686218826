.overview.p-tooltip .p-tooltip-text {
  background: white !important;
  padding: 0 !important;
  box-shadow: none !important;
}

.overview.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: #ffffff !important;
}
.overview.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: #ffffff !important;
}
.overview.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: #ffffff !important;
}
.overview.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: #ffffff !important;
}