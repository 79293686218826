
/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/



// Bootstrap functions
@import 'bootstrap/functions';
@import "bootstrap/mixins";

// Argon functions
@import "custom/functions";
@import "custom/variables";
@import "bootstrap/variables";

// Argon mixins
@import "custom/mixins";

// Bootstrap components
@import "bootstrap/root";
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "bootstrap/images";
@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";
@import "bootstrap/transitions";
@import "bootstrap/dropdown";
@import "bootstrap/button-group";
@import "bootstrap/input-group";
@import "bootstrap/custom-forms";
@import "bootstrap/nav";
@import "bootstrap/navbar";
@import "bootstrap/card";
@import "bootstrap/breadcrumb";
@import "bootstrap/pagination";
@import "bootstrap/badge";
@import "bootstrap/jumbotron";
@import "bootstrap/alert";
@import "bootstrap/progress";
@import "bootstrap/media";
@import "bootstrap/list-group";
@import "bootstrap/close";
@import "bootstrap/modal";
@import "bootstrap/tooltip";
@import "bootstrap/popover";
@import "bootstrap/carousel";
@import "bootstrap/utilities";
@import "bootstrap/spinners";
@import "bootstrap/print";

// Argon components
@import 'custom/components';
@import 'custom/utilities';
@import 'custom/vendors';

// Prime Component
@import 'primereact/primereact.scss';

// React differences
@import 'react/react-differences';


.border-bottom-2 {
  border-bottom: 2px solid var(--primary) !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #2065AC !important;
}

.p-inputswitch {
  width: 3rem !important;
  height: 1.5rem !important;
}

.card.no-shadow {
  box-shadow: none !important;
}